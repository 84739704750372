import React, { useEffect, useRef, useState } from "react";

import "chartjs-plugin-datalabels";
import Title from "../../components/title";
import { api as apiv2 } from "../../services/api.v2";
import "react-data-table-component-extensions/dist/index.css";
import { arrayBases } from "../../util/loja";
import { saveAs } from "file-saver";

import toast from "react-hot-toast";
import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import { DatePikerFilter } from "../../components/DatePikerFilter/DatePikerFilter";
import { Box, CircularProgress, Paper, Stack, Tab, Tabs } from "@mui/material";
import { MultiSelectBase } from "../../components/MultiSelect/MultiSelectBase/MultiSelectBase";
import { MultiSelectLojas } from "../../components/MultiSelect/MultiSelectLojas/MultiSelectLojas";
import dayjs from "dayjs";
import { NoData } from "../../components/NoData/NoData";
import { LoadingTable } from "../../components/Loading/LoadingTable";

import { a11yProps } from "../../util/a11yProps";
import { CustomTabPanel } from "../../components/componentsAnaliseClientes/CustomTabPanel";
import DataTable, { defaultThemes } from "react-data-table-component";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";
import { GenericSelect } from "../../components/GenericSelect";
import { columnsTable, columnsTableRegisters } from "./columnsTable";
import { Button } from "react-bootstrap";
import { CircleLoader } from "react-spinners";

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

const listaDeCampanhas = [
  { label: "Baby+ Convênio", value: "campanha_baby+_convenio" },
  { label: "Baby+ Gestante", value: "campanha_baby+_gestante" },
  { label: "Black Friday", value: "campanha_black_friday" },
];

function Campaign() {
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dataPage, setDataPage] = useState([]);

  const [, setIsLoading] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [campanhaSelecionada, setCampanhaSelecionada] = useState(null);

  const { userData } = useUserData();

  const [filterShop, setFilterShop] = useState([]);
  const accessLevel = userData?.nivelAcesso || [];
  const acessBase = userData?.baseAcesso;
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const currencyDay = dayjs().format("YYYY-MM-DD");
  const [paramsConfig, setParamsConfig] = useState({
    base: [],
    first_period: firstDayOfMonth,
    final_period: currencyDay,
    stores: [],
  });
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const timerRef = useRef();

  const buscarNivelAcesso = () => {
    if (!!accessLevel && !!acessBase) {
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      setLojasDisponiveis(accessLevel);
      setCampanhaSelecionada(null);
      setDataPage([]);
      setParamsConfig({
        base: [],
        first_period: firstDayOfMonth,
        final_period: currencyDay,
        stores: [],
      });
      setFilterShop([]);
    } else {
      setIsLoading(false);
    }
  };

  const onChangeMultiSelect = (value, name) => {
    let filterId = value.map((val) => val.value);
    setParamsConfig((old) => ({
      ...old,
      [name]: filterId,
    }));
  };
  const onChangeDatePicker = (value) => {
    setParamsConfig((old) => ({ ...old, ...value }));
  };

  const handleMultiSelectFilterShop = (value) => {
    setFilterShop(value);
    setParamsConfig((old) => ({
      ...old,
      stores: value.map((item) => item.value),
    }));
  };

  async function preencherDados() {
    console.log({ paramsConfig });
    if (!campanhaSelecionada?.value) {
      toast.error("Selecione uma campanha");
      return;
    }
    setIsLoadingData(true);
    if (timerRef.current) {
      timerRef.current.style.opacity = 0;
    }

    // window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");

    try {
      const response = await apiv2.get("/customers/campaign/registers", {
        params: {
          store: paramsConfig.stores ?? [],
          base: paramsConfig.base ?? [],
          campaign: campanhaSelecionada?.value,
          start_at: paramsConfig.first_period ?? null,
          end_at: paramsConfig.final_period ?? null,
        },
      });
      if (response.status === 200) {
        const data = response.data;

        setDataPage(data);
        toast.success("Dados recebidos", {
          duration: 5000,
          id: toastId,
        });
      } else {
        toast.error("Ocorreu um erro ao carregar, por favor tente novamente", {
          id: toastId,
        });
      }
    } catch (error) {
      toast.error(error, {
        id: toastId,
      });
    }

    setIsLoadingData(false);
  }
  const handleFilter = () => {
    preencherDados();
  };

  const handleClearFilter = () => {
    buscarNivelAcesso();
  };

  const handleDownloadXls = async () => {
    try {
      if (!campanhaSelecionada?.value) {
        toast.error("Selecione uma campanha");
        return;
      }
      setIsLoadingDownload(true);
      const response = await apiv2.get("/customers/campaign/registers/export", {
        responseType: "blob",
        params: {
          store: paramsConfig.stores ?? [],
          campaign: campanhaSelecionada?.value,
        },
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Define o nome do arquivo
      const fileName = "cadastros_campanha.xlsx";
      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Erro ao tentar baixar registros da pagina");
    } finally {
      setIsLoadingDownload(false);
    }
  };

  useEffect(() => {
    if (!!userData) {
      buscarNivelAcesso();
    }
  }, [userData]);

  useEffect(() => {
    console.log("aqui");

    if (basesDisponiveis.length <= 0) return;

    console.log("aqui2");
    preencherDados();
  }, [basesDisponiveis]);

  return (
    <>
      <>
        {PermissionsPage("analiseClientes") && (
          <>
            <Title title="Campanhas" />
            <Box
              mx={2}
              sx={{
                mt: 1,
              }}
            >
              <Stack direction={"row"} gap={2} mb={2}>
                <DatePikerFilter
                  paramsConfig={paramsConfig}
                  onChange={onChangeDatePicker}
                  isLoading={isLoadingData}
                  showButtonFilter={false}
                />
                <Box width="100%">
                  <Stack gap={2}>
                    <MultiSelectBase
                      bases={basesDisponiveis}
                      onChange={onChangeMultiSelect}
                      paramsConfig={paramsConfig}
                      isLoading={isLoadingData}
                    />

                    <MultiSelectLojas
                      lojas={lojasDisponiveis}
                      onChange={handleMultiSelectFilterShop}
                      paramsConfig={paramsConfig}
                      isLoading={isLoadingData}
                      shops={filterShop}
                    />
                    <GenericSelect
                      onChange={(value) => setCampanhaSelecionada(value)}
                      isLoading={isLoadingData}
                      name="campanha"
                      label="Campanha"
                      options={listaDeCampanhas}
                      value={campanhaSelecionada}
                      placeholder="Selecione uma campanha"
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    mb={2}
                  >
                    <Stack direction={"row"} spacing={1} mt={2}>
                      <ButtonFilter
                        style={{
                          width: "fit-content",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                        onClick={handleClearFilter}
                        disabled={isLoadingData}
                      >
                        LIMPAR FILTROS
                      </ButtonFilter>
                      <ButtonToday
                        style={{
                          width: "fit-content",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                        onClick={handleFilter}
                        disabled={isLoadingData}
                      >
                        FILTRAR
                      </ButtonToday>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              {isLoadingData && <LoadingTable isLoading={isLoadingData} />}

              {!isLoadingData && campanhaSelecionada && (
                <>
                  <Box
                    sx={{ width: "100%" }}
                    px={2}
                    component={Paper}
                    variant="outlined"
                    elevation={1}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{
                          "& .MuiTabs-flexContainer": {
                            width: "100%",
                            justifyContent: "center",
                          },
                        }}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Cadastros por loja" {...a11yProps(0)} />
                        <Tab label="Clientes cadastrados" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <Box px={5}>
                        <Box
                          component={Paper}
                          my={3}
                          variant="outlined"
                          elevation={1}
                        >
                          <DataTable
                            dense
                            noDataComponent={NoData}
                            columns={columnsTable}
                            customStyles={customStyles}
                            data={dataPage.registersByStore ?? []}
                          />
                        </Box>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Button
                          type="button"
                          variant="success"
                          onClick={handleDownloadXls}
                          disabled={isLoadingDownload}
                        >
                          Baixar planilha
                          {isLoadingDownload && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginLeft: 8 }}
                            />
                          )}
                        </Button>
                        <DataTable
                          dense
                          noDataComponent={NoData}
                          columns={columnsTableRegisters}
                          customStyles={customStyles}
                          data={dataPage.registers ?? []}
                        />
                      </Box>
                    </CustomTabPanel>
                  </Box>
                </>
              )}
              {!campanhaSelecionada && (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    marginBottom: "40px",
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontStyle: "italic",
                  }}
                >
                  Selecione uma campanha
                </p>
              )}
            </Box>
          </>
        )}
      </>
    </>
  );
}

export default Campaign;

export function onlyUnsignedNumbers(_string) {
  return _string?.replace(/[^0-9]+/g, "");
}

export function formatDate(data) {
  const [ano, mes, dia] = data.split("-");
  return `${dia}/${mes}/${ano}`;
}

export function formatDateTime(dateTime) {
  const date = new Date(dateTime);
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  })
    .format(date)
    .replace(",", "");
}
